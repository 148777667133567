import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslitPipe } from '@app/pipes/translit.pipe';

@Component({
  selector: 'app-horizontal-scroll',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslitPipe],
  templateUrl: './horizontal-scroll.component.html',
  styleUrl: './horizontal-scroll.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalScrollComponent {
  @Input() method: Function | undefined;
  @Input() subtitle: string = '';
  @Input() mainTitle: string = '';
  @Output() clickEmitter = new EventEmitter<string>();
  name!: string;

  constructor() {
    this.name = 'class-' + String(Math.floor(Math.random() * 1000000));
    this.resetScrolling();
  }

  resetScrolling() {
    setTimeout(() => {
      this.initScrolling();
    }, 100);
  }

  initScrolling() {
    const selector = `.scroll-container.${this.name}`;
    const slider = document.querySelector(selector) as any;

    if (!slider) {
      return;
    }

    let isDown = false;
    let startX: any;
    let scrollLeft: any;

    slider.addEventListener('mousedown', (e: any) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e: any) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }

}
